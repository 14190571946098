import { useCallback, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { Stack } from "@mui/system";
import { TextField } from "@mui/material";
import { usePopover } from "src/hooks/use-popover";

import { Scrollbar } from "../scrollbar";

export const FilterSelect = ({
  label,
  options,
  placeholder,
  value,
  setValue,
  withSearch,
  isExclude,
  smallText,
  nonValue,
  setNonValue,
}) => {
  const [values, setValues] = useState([]);
  const [search, setSearch] = useState("");

  const popover = usePopover();

  useEffect(() => {
    setValues([...options]);
  }, [options]);

  useEffect(() => {
    const filteredValues = values?.filter((val) =>
      val?.label?.toLowerCase().includes(search?.toLowerCase())
    );
    if (search) {
      setValues(filteredValues);
    } else {
      setValues([...options]);
    }
  }, [search, options]);

  const handleSearch = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setSearch(event.target.value);
  }, []);

  return (
    <>
      <Button
        color="inherit"
        endIcon={
          <SvgIcon>
            <ChevronDownIcon />
          </SvgIcon>
        }
        sx={{ p: 0 }}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <Typography
          fontSize={smallText ? 13 : 14}
          fontWeight="600"
          sx={{ whiteSpace: "nowrap" }}
        >
          {label}
        </Typography>
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ style: { width: 200 } }}
      >
        {withSearch && (
          <Stack sx={{ px: 1, py: 1 }}>
            <TextField
              type="search"
              placeholder={placeholder}
              onChange={handleSearch}
              hiddenLabel
              size="small"
              value={search}
              onKeyDown={(e) => e.stopPropagation()}
            />
          </Stack>
        )}
        <Scrollbar style={{ maxHeight: 200 }}>
          {values?.map((option) => (
            <MenuItem
              key={option.label}
              sx={{ w: 1 }}
              selected={value === option?.value}
            >
              <Stack direction={"row"} sx={{ width: "100%" }}>
                <Typography
                  onClick={() => setValue(option?.value)}
                  sx={{
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    flexGrow: 1,
                  }}
                >
                  {option.label}
                </Typography>
                {isExclude && (
                  <IconButton
                    onClick={() => setNonValue(option.value)}
                    sx={{ p: 0 }}
                  >
                    <RemoveCircleOutlineIcon
                      sx={{
                        color:
                          nonValue === option.value
                            ? "red"
                            : "gray",
                        opacity:
                          nonValue === option.value
                            ? 1
                            : 0.5,
                      }}
                      fontSize="small"
                    />
                  </IconButton>
                )}
              </Stack>
            </MenuItem>
          ))}
        </Scrollbar>
      </Menu>
    </>
  );
};
