import { useCallback, useState } from "react";

export const useSelection = (items = []) => {
  const [selected, setSelected] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleSelectAll = useCallback(() => {
    setSelected([...items]);
    setSelectAll(true);
  }, [items]);

  const handleSelectOne = useCallback((item) => {
    setSelected((prevState) => [...prevState, item]);
  }, []);

  const handleSelectPage = (val) => {
    const newSelect = [...new Set([...selected, ...val])];
    setSelected(newSelect);
  };

  const handleDeSelectPage = (val) => {
    const newIds = [...selected]?.filter((item) => !val?.includes(item));
    setSelectAll(false);
    setSelected(newIds);
  };

  const handleDeselectAll = useCallback(() => {
    setSelectAll(false);
    setSelected([]);
  }, []);

  const handleDeselectOne = useCallback((item) => {
    setSelectAll(false);
    setSelected((prevState) => {
      return prevState.filter((_item) => _item !== item);
    });
  }, []);

  return {
    handleDeselectAll,
    handleDeselectOne,
    handleSelectAll,
    handleSelectPage,
    handleDeSelectPage,
    handleSelectOne,
    selected,
    selectAll,
  };
};
